/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { ApiClientService } from './api-client.service';
import { map } from 'rxjs/operators';
import { AppSettings } from '../app-settings';
import { AuthenticationService } from './authentication-service.service';

@Injectable({
  providedIn: 'root'
})

export class UserService {

  constructor(private apiClient: ApiClientService) { }

  public getUserInfo() {
    return this.apiClient.post('api/user/getuserinfo', '');
  }

  public updateUserInfo(profile: any) {
    return this.apiClient.put('api/mobile/user/meV2', JSON.stringify(profile));
    //deprecated
    //return this.apiClient.put('api/mobile/user/me', JSON.stringify(profile));
  }

  public updateUserPushNotificationModel() {
    return this.apiClient.get('api/user/me/togglepushnotifichange');
  }

  public getUserDashboard() {
    return this.apiClient.get('api/mobile/user/getdashboard' + '?__' + (new Date()).getTime());
  }

  public setRadius(radius: number) {
    return this.apiClient.postBackground('api/mobile/user/setradius', JSON.stringify({
      radius
    }));
  }

  public setAgreement(initials: string) {
    return this.apiClient.post('api/mobile/user/setagreement', JSON.stringify({
      initials
    }));
  }

  public setElectronicSignature(electronicSignature: string) {
    return this.apiClient.post('api/mobile/user/setelectronicsignature', JSON.stringify({
      electronicSignature
    }));
  }

  public getAccessToken() {
    return this.apiClient.postBackground('api/mobile/user/GetAccessToken', '');
  }

  public uploadAvatar(data, success, error) {
    const xhr: XMLHttpRequest = new XMLHttpRequest();
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          success(JSON.parse(xhr.response));
        } else {
          error(xhr.response);
        }
      }
    };

    const url = AppSettings.webApiRootUrl + 'api/mobile/user/uploadavatar';

    xhr.open('POST', url, true);
    xhr.setRequestHeader('Authorization', 'Bearer ' + AuthenticationService.authenticationToken);
    xhr.send(data);
  }

  public GetAvatarUrl(userId) {
    return this.apiClient.get(`api/mobile/user/getavatarurl/${userId}` + '?__' + (new Date()).getTime());
  }

  public getTrpUser(id: number) {
    return this.apiClient.get(`api/mobile/user/${id}/trp-info` + '?__' + (new Date()).getTime());
  }

  public getUserImage(userId: number) {
    return AppSettings.webApiRootUrl + `api/images/getuserphoto?id=${userId}&width=80&height=80`;
  }

  public shouldShowDisablePushModal() {
    return this.apiClient.post('api/map/shouldshowdisablepushmodal', null);
  }

  public getIcommGuid() {
    return this.apiClient.get('/api/companies/company');
  }
}
