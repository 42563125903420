/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable eqeqeq */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import {Injectable} from '@angular/core';
import { ApiClientService } from './api-client.service';
import { AppSettingsService } from './app-settings-service.service';

@Injectable({
    providedIn: 'root',
  })
export class AlertServiceService {
    static filters: any = {
        filterDateType: '',
        analystAlertPriorityType: '',
        alertTypeId: '',
        categoryId: '',
        scoutAlert: true,
        analystAlert: true,
        pushAlert: true,
        sortAlertType: null,
        destination: null,
        country: null,
        currentMapLocation: Location,
        currentMapRadiusInMeters: Number
    };

    constructor(private apiClient: ApiClientService, private appSettingsService: AppSettingsService) {
    }

    public static clearFilters() {
        AlertServiceService.filters.scoutAlert =
          AlertServiceService.filters.analystAlert =
        AlertServiceService.filters.pushAlert = true;

        AlertServiceService.filters.categoryId = '';
	    AlertServiceService.filters.alertTypeId = '';
	    AlertServiceService.filters.filterDateType = '';
        AlertServiceService.filters.analystAlertPriorityType = '';
    }

    public static getFiltersParams() {
      const destination = AlertServiceService.filters.destination != undefined ? AlertServiceService.filters.destination.location : null;

        return {
            alertTypeId: AlertServiceService.filters.alertTypeId,
            scoutAlert: AlertServiceService.filters.scoutAlert,
            analystAlert: AlertServiceService.filters.analystAlert,
            pushAlert: AlertServiceService.filters.pushAlert,
            sortAlertType: AlertServiceService.filters.sortAlertType,
            filterDateType: AlertServiceService.filters.filterDateType,
            analystAlertPriorityType: AlertServiceService.filters.analystAlertPriorityType,
          destination,
            currentMapLocation: null,
            currentMapRadiusInMeters: 0
        };
    }

    public getViewAlertViewModel(alertId) {
        return this.apiClient.post('api/alert/alertsapi/getviewalertviewmodel', alertId);
    }

    public getSelectAlertTypeCategoryViewModel(alertId) {
        return this.apiClient.post('api/alert/alertsapi/getselectalerttypecategoryviewodel', alertId);
    }

    public getAlertTypeCategoriesViewModel() {
        return this.apiClient.post('api/alert/alertsapi/getalerttypecategoriesviewmodel', '');
    }

    public getAlertTypesViewModel(alertTypeCategory) {
      const alertTypeCategoryId = (alertTypeCategory || {}).id;
      return this.apiClient.post('api/alerttypes/list', JSON.stringify(alertTypeCategoryId));
      // above url is equivalent to lower url
      // return this.apiClient.post('api/alert/AlertsApi/GetAlertTypesViewModel', JSON.stringify(alertTypeCategoryId));
    }

    public getSelectAlertTypeViewModel(alertId, selectedAlertTypeCategoryId) {
      return this.apiClient.post('api/alert/alertsapi/getselectalerttypeviewmodel', JSON.stringify({ alertId, selectedAlertTypeCategoryId }));
    }

    public getEditViewModel(alertId) {
      return this.apiClient.post('api/alert/alertsapi/geteditalertviewmodel', JSON.stringify({ alertId }));
    }

    public getAddEmergencyViewModel() {
        return this.apiClient.post('api/alert/alertsapi/getaddemergencyviewmodel', '');
    }

    public saveAlert(alert) {
        return this.apiClient.post('api/alert/alertsapi/createorupdate', JSON.stringify(alert));
        // Doesn't return anything
    }

    public getAlert(alertId) {
      return this.apiClient.get(`api/alert/${alertId}` + '?__' + (new Date()).getTime());
    }

    public rejectAlert(alertId) {
        return this.apiClient.post('api/alert/alertsapi/rejectalert', alertId);
    }

    public thankAlert(alertId) {
        return this.apiClient.post('api/alert/alertsapi/thankalert', alertId);
    }

    public validateAlert(alertId) {
        return this.apiClient.post('api/alert/alertsapi/validatealert', alertId);
    }
/*
    public removeAlert(alertId) {
      return this.apiClient.post('api/alert/manageralert', alertId);
    }
*/
    public getViewFilterAlertsViewModel() {
        return this.apiClient.post('api/alert/alertsapi/getfilteralertsviewmodel', '');
    }

    public getCategories() {
      return this.apiClient.get('api/alert/categories' + '?__' + (new Date()).getTime());
    }

    public getTypes(categoryId) {
      return this.apiClient.get(`api/alert/types/${categoryId}` + '?__' + (new Date()).getTime());
    }

    public getDestination(search: String){
        return this.apiClient.get(`/api/alertdestination/getall/${search}`);
    }

    public getAlerts(location: Location, radiusInMeters: number) {
      const filtersParams = AlertServiceService.getFiltersParams();
        // filtersParams.analystAlertPriorityType = '';
        filtersParams.pushAlert = false;
        filtersParams.currentMapLocation = location;
        filtersParams.currentMapRadiusInMeters = radiusInMeters;

        return this.apiClient.post('api/alert/list', JSON.stringify(filtersParams));
    }

    public getAlertListPro(location: any, radiusInMeters: number, skip: number, destinationText, destinationId) {
      const filtersParams = {
        CurrentMapRadiusInMeters: radiusInMeters,
        CurrentMapLocation: {
          Latitude: location.latitude,
          Longitude: location.longitude
        },
        destinationId,
        destination: destinationText,
        skip: skip || 1, // skip is 1 based and simillar to page number
        take:10,
        checkClientCompanyId: true
     };
    return this.apiClient.post('api/alert/list/pro', JSON.stringify(filtersParams));
  }

    public getEmergencyDefaultType() {
      return this.apiClient.get('api/alert/get-default-emergency-type' + '?__' + (new Date()).getTime());
    }
}
